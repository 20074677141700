















































.live-pause-modal {
    color: #060111;
}
