



















































































































































.live-ing-center {
    box-sizing: border-box;
    flex: 1;
    position: relative;
    padding-top: 10px;
    padding-bottom: 30px;
    #container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        .top-box {
            display: flex;
            .left-bag-coupon {
                display: flex;
                justify-content: space-between;
                width: 130px;
                .item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border-radius: 14px;
                    background-color: rgba(0, 0, 0, 0.23);
                    cursor: pointer;
                    .icon {
                        width: 100%;
                        height: 100%;
                        transform: rotate(35deg) translate(0, 9px);
                    }
                    .item-container {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                }
                .bag-box {
                    .countdown {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 50px;
                        height: 20px;
                        font-size: 12px;
                        color: #fff;
                        border-radius: 10px;
                        background-color: rgba(0, 0, 0, 0.5);
                        opacity: 0.9;
                    }
                }
                .coupon-box {
                    .corner-mark {
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translate(40%, -40%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        font-size: 14px;
                        color: #d50715;
                        background: #fece0a;
                        box-shadow: 0px 4px 7px 0px rgba(94, 57, 1, 0.3);
                        border-radius: 50%;
                    }
                }
            }
            .right-goods-info {
                flex: 1;
                box-sizing: border-box;
                display: flex;
                height: 140px;
                margin-left: 40px;
                .paster {
                    flex: 1;
                    margin-right: 15px;
                    text-align: end;
                    .paster-img {
                        max-height: 150px;
                    }
                }
            }
        }
        .bottom-box {
            display: flex;
            justify-content: space-between;
            height: 265px;
            /* overflow: hidden; */
            .left-bullet-chat {
                width: 50%;
                height: 100%;
                overflow: hidden;
                .bullet-chat-list {
                    .item {
                        width: fit-content;
                        margin-bottom: 10px;
                        padding: 5px 10px;
                        word-break: break-all;
                        font-size: 16px;
                        color: #ff3e6c;
                        border-radius: 15px;
                        background-color: rgba(0, 0, 0, 0.3);
                        .content {
                            margin-left: 9px;
                            color: #fff;
                        }
                    }
                    .item:last-child {
                        margin-bottom: 0;
                    }
                }
            }
            .right-goods-popup {
                box-sizing: border-box;
                align-self: flex-end;
                width: 174px;
                height: fit-content;
                background-color: #fff;
                border-radius: 6px;
                .img-box {
                    width: 170px;
                    height: 170px;
                    padding: 2px 2px 0;
                    overflow: hidden;
                    .goods-img {
                        width: 100%;
                        height: 100%;
                        vertical-align: middle;
                        border-radius: 6px 6px 0 0;
                    }
                }
                .goods-info {
                    margin: 7px 5px;
                    overflow: hidden;
                    .title {
                        box-sizing: border-box;
                        font-size: 14px;
                        line-height: 18px;
                        color: #222;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2; /*几行就写数字几*/
                        text-overflow: ellipsis;
                    }
                    .coupon {
                        display: flex;
                        width: 100%;
                        height: 42px;
                        margin-top: 5px;
                        color: #fff;
                        background-image: linear-gradient(to right, #f41c4f, #fd6f6c);
                        overflow: hidden;
                        .left-coupon-info {
                            flex: 1;
                            align-self: center;
                            text-align: center;
                            font-size: 14px;
                            .number {
                                font-size: 20px;
                            }
                        }
                        .decorate {
                            display: flex;
                            flex-direction: column;
                            justify-content: space-between;
                            width: 8px;
                            height: 100%;
                            &::before,
                            &::after {
                                content: "";
                                width: 8px;
                                height: 8px;
                                border-radius: 50%;
                                background-color: #fff;
                                transform: translate(0, -50%);
                            }
                            &::after {
                                transform: translate(0, 50%);
                            }
                        }
                        .right-action {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            width: 38px;
                            height: 100%;
                            font-size: 18px;
                            font-style: italic;
                        }
                    }
                }
            }
        }
    }
}

::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
::v-deep .el-scrollbar__thumb {
    display: none;
}
