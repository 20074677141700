


























































































































































.live-room {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    user-select: none;

    .container {
        position: relative;
        box-sizing: border-box;
        display: flex;
        height: 100%;
        padding: 20px;
        background-color: #070932;
        .data-board {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            top: 0;
            left: 22px;
            width: 104px;
            height: 93px;
            color: #fff;
            font-size: 14.5px;
            font-weight: 700;
            background: url("../../assets/image/student/live/flag.png") no-repeat;
            background-size: 100% 100%;
            cursor: pointer;
        }
    }
}

.radius {
    box-sizing: border-box;
    border-radius: 20px;
    overflow: hidden;
    /* background-color: #0c0e3f; */
}
::v-deep .popularity,
::v-deep .bullet-chat {
    @extend .radius;
}
