















































































































































.head-user-info {
    position: relative;
    box-sizing: border-box;
    width: 100%;
    user-select: none;
    .container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        color: #fff;
        .left-user {
            position: relative;
            box-sizing: border-box;
            /* width: 222px; */
            width: calc(222px - 53px);
            outline: none;
            .user-box {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 48px;
                background-color: rgba(0, 0, 0, 0.3);
                border-radius: 24px;
                .avatar {
                    width: 48px;
                    height: 48px;
                    border-radius: 50%;
                    cursor: pointer;
                }
                .user-info-box {
                    flex: 1;
                    margin-left: 5px;
                    text-align: center;
                    font-size: 15px;
                    font-weight: 700;
                    overflow: hidden;
                    .like-num {
                        font-size: 11px;
                        font-weight: normal;
                    }
                    .username {
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        overflow: hidden;
                    }
                }
                .attention {
                    display: flex;
                    align-items: center;
                    width: 53px;
                    height: 100%;
                    padding: 0 4px;
                    .already-attention,
                    .no-attention {
                        width: 53px;
                        height: 40px;
                        font-size: 15px;
                        line-height: 40px;
                        text-align: center;
                        background: linear-gradient(85deg, #fb2d69, #ff3e6c, #fc2a7d);
                        border-radius: 20px;
                        cursor: pointer;
                    }
                    .already-attention {
                        font-size: 19px;
                        background: linear-gradient(137deg, #ffa200, #f78b43);
                    }
                }
            }
            .tag-list {
                display: flex;
                flex-wrap: wrap;
                width: 130%;
                /* margin-top: 10px; */
                margin-left: -5px;
                color: #fff;
                .tag-item {
                    display: flex;
                    align-items: center;
                    height: 28px;
                    padding: 0 10px;
                    margin: 10px 0 0 5px;
                    border-radius: 14px;
                    background-color: rgba(0, 0, 0, 0.3);
                    font-size: 14px;
                    .char {
                        width: 15px;
                        height: 15px;
                        margin-right: 8px;
                        text-align: center;
                        line-height: 15px;
                        font-size: 13px;
                        border-radius: 6px 6px 0 6px;
                        background-image: linear-gradient(45deg, #f0c237, #f29f1e);
                    }
                }
            }
            .user-info-popup {
                box-sizing: border-box;
                position: absolute;
                top: calc(100% + 10px);
                left: 0;
                width: 450px;
                padding: 23px 20px;
                background-color: #fff;
                box-shadow: 0 0 18px 2px rgba(51, 51, 51, 0.1);
                border-radius: 20px;
                transform-origin: top;
                transform: scale(1, 0);
                z-index: 9;
                .popup-base-user-info {
                    border-bottom: 1px solid #ddd;
                    .popup-avatar {
                        position: relative;
                        width: 58px;
                        height: 58px;
                        .popup-avatar-img {
                            position: relative;
                            width: 100%;
                            height: 100%;
                            z-index: 9;
                            border-radius: 50%;
                        }
                        &::before {
                            content: "";
                            display: block;
                            box-sizing: content-box;
                            position: absolute;
                            top: -5px;
                            left: -5px;
                            right: -5px;
                            bottom: -5px;
                            background-color: rgba(0, 0, 0, 0.05);
                            border-radius: 50%;
                        }
                    }
                    .popup-username {
                        margin: 24px 0 20px;
                        font-size: 18px;
                        color: #1a1727;
                        font-weight: 700;
                    }
                }
                .popup-other-user-info {
                    font-size: 16px;
                    .number-info {
                        display: flex;
                        justify-content: space-between;
                        margin: 18px 0;
                        color: #1a1727;
                    }
                    .about {
                        color: #5d5a6c;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 3;
                        -webkit-box-orient: vertical;
                    }
                }
            }
        }
        .right-audience {
            display: flex;
            align-items: center;
            height: 48px;
            .avatar-list {
                display: flex;
                .avatar-item {
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    overflow: hidden;
                    margin-left: 5px;
                    .avatar-img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
            .people-sum {
                min-width: 60px;
                height: 36px;
                margin: 0 5px;
                line-height: 36px;
                text-align: center;
                background-color: #000;
                opacity: 0.3;
                border-radius: 18px;
            }
        }
    }
}
