

















































































































.my-popup {
    .container {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        .mask {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.3);
        }
        .content {
            box-sizing: border-box;
            flex-direction: column;
            display: flex;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            height: 80%;
            background-color: #fff;
            z-index: 99;
            border-radius: 20px 20px 0 0;
            .head {
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                align-items: center;
                box-sizing: border-box;
                padding: 17px;
                color: #060111;
                border-bottom: 1px solid #ddd;
                .left,
                .right {
                    width: 50px;
                    cursor: pointer;
                }
                .left {
                    font-size: 24px;
                }
                .right {
                    text-align: end;
                    color: #4b45ff;
                    font-size: 16px;
                }
                .center-title {
                    text-align: center;
                    font-weight: 700;
                    font-size: 18px;
                }
            }
            .body {
                box-sizing: border-box;
                flex: 1;
                overflow: hidden;
            }
        }
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
