





















































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.live-main {
    position: relative;
    box-sizing: border-box;
    width: 562px;
    height: 100%;
    margin: 0 20px;
    background-color: skyblue;
    overflow: hidden;
    .mask {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: 0;
        z-index: 999;
    }
    .bg-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    .bg-blur {
        filter: blur(5px);
    }
    .countdown {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 140px;
        height: 140px;
        line-height: 140px;
        text-align: center;
        font-size: 80px;
        color: #fff;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 9;
    }
    .container {
        width: 100%;
        height: 100%;
        .live-video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            ::v-deep video {
                width: 100%;
                height: 100%;
                object-fit: cover !important;
            }
        }
        .content {
            box-sizing: border-box;
            display: flex;
            width: 100%;
            height: 100%;
            padding: 12px 10px;
            .state {
                flex: 1;
                display: flex;
                flex-direction: column;
            }
            .center {
                flex: 1;
            }
        }
    }
}
